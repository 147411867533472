import React from 'react';

const Counter = () => {
  const [count, setCount] = React.useState(0);
  return (
    <div className="text-center">
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-1 rounded"
        onClick={() => setCount(count + 1)}
      >
        Increment
      </button>
      <span className="mx-3">{count}</span>
    </div>
  );
};

export default Counter;
